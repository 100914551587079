%reset-style {
  margin: 0;
}

%button-style {
  border: 0;
  background: #2a0032;
  border-radius: 7px;
  color: white;
  cursor: pointer;
  font-family: 'Circular Book';
}

%container-style {
  max-width: 1200px;
  margin: 0 auto;
}
