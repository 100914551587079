@import-normalize; /* bring in normalize.css styles */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap');

@font-face {
  font-family: 'Circular Book';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Assets/fonts/Circular/Circular-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Circular Book';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('./Assets/fonts/Circular/Circular-BookItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Circular Black';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Assets/fonts/Circular/Circular-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Circular Black';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('./Assets/fonts/Circular/Circular-BlackItalic.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Circular';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('./Assets/fonts/Circular/Circular-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('./Assets/fonts/Circular/Circular-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Circular Medium';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('./Assets/fonts/Circular/Circular-MediumItalic.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Circular Medium';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./Assets/fonts/Circular/Circular-Medium.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
